import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { IconSpinner, IconCheck } from '../../../components';

import css from './SaveFilters.module.css';

const SaveFilters = props => {
  const {
    onSaveDefaultFilters,
    onDeleteDefaultFilters,
    saveDefaultFiltersError,
    saveDefaultFiltersInProgress,
    saveDefaultFiltersSuccess,
    deleteDefaultFiltersInProgress,
    deleteDefaultFiltersError,
    defaultFilters,
  } = props;

  const disableSaveFilter = !!saveDefaultFiltersInProgress;
  const disableDeleteFilter = !!deleteDefaultFiltersInProgress;

  return (
    <div>
      <div className={css.defaultFilters}>
        <div className={css.saveFilterWrapper}>
          <span
            className={classNames({ [css.disable]: disableSaveFilter }, css.saveDefaultFilters)}
            onClick={onSaveDefaultFilters}
          >
            <FormattedMessage id="MainPanelHeader.saveDefaultFilters" />
          </span>
          {!!saveDefaultFiltersInProgress ? (
            <span>
              <IconSpinner className={css.spinner} />
            </span>
          ) : null}
        </div>
        <div className={css.saveFilterWrapper}>
          <span
            className={classNames({ [css.disable]: disableDeleteFilter }, css.deleteDefaultFilters)}
            onClick={onDeleteDefaultFilters}
          >
            <FormattedMessage id="MainPanelHeader.deleteDefaultFilters" />
          </span>
          {!!deleteDefaultFiltersInProgress ? (
            <span>
              <IconSpinner className={css.spinner} />
            </span>
          ) : null}
        </div>
      </div>
      {!!saveDefaultFiltersError ? (
        <div className={css.error}>
          <FormattedMessage id="MainPanelHeader.errorSaveDefaultFilters" />
        </div>
      ) : null}
      {!!deleteDefaultFiltersError ? (
        <div className={css.error}>
          <FormattedMessage id="MainPanelHeader.errorDeleteDefaultFilters" />
        </div>
      ) : null}
    </div>
  );
};

SaveFilters.defaultProps = {
  onSaveDefaultFilters: null,
  onDeleteDefaultFilters: null,
  saveDefaultFiltersError: null,
  saveDefaultFiltersInProgress: false,
  saveDefaultFiltersSuccess: null,
  deleteDefaultFiltersInProgress: false,
  deleteDefaultFiltersError: null,
  defaultFilters: null,
};

SaveFilters.propTypes = {
  onSaveDefaultFilters: func.isRequired,
  onDeleteDefaultFilters: func.isRequired,
};



export default SaveFilters;
