import React, { Component } from 'react';
import { arrayOf, func, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { formatCurrencyMajorUnit } from '../../../util/currency';

import IconPlus from '../IconPlus/IconPlus';
import RangeFilterForm from '../RangeFilterForm/RangeFilterForm';

import css from './RangeFilterPlain.module.css';

const RADIX = 10;

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class RangeFilterPlainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  handleChange(values) {
    const { onSubmit, queryParamNames } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const vals = { [queryParamName]: values[queryParamName] }
    onSubmit(vals);
  }

  handleClear() {
    const { onSubmit, queryParamNames } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const vals = { [queryParamName]: null }
    onSubmit(vals);
  }

  toggleIsOpen() {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      formLabel,
      name,
      queryParamNames,
      initialValues,
      min,
      max,
      step,
      intl,
      marketplaceCurrency,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const queryParam = getQueryParamName(queryParamNames);
    const hasInitialValues =
      !!initialValues && !!initialValues[queryParam];
    const initialValue = !!initialValues && !!initialValues[queryParam];
    const namedInitialValues = { [name]: initialValues[queryParam] };

    const labelSelection = hasInitialValues
      ? intl.formatMessage(
          { id: 'RangeFilter.labelSelected' },
          {
            value: initialValue,
          }
        )
      : null;
    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button type="button" className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={css.labelWrapper}>
                <span className={css.label}>
                  {label}
                </span>
              </span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={hasInitialValues} />
              </span>
            </span>
          </button>
        </div>
        <div className={css.formWrapper}>
          <RangeFilterForm
            id={id}
            name={name}
            label={formLabel}
            initialValues={namedInitialValues}
            onChange={this.handleChange}
            intl={intl}
            contentRef={node => {
              this.filterContent = node;
            }}
            min={min}
            max={max}
            step={step}
            liveEdit
            isOpen={this.state.isOpen}
            isInSideBar
            style={{ minWidth: '160px' }}
          >
            <button className={css.clearButton} onClick={this.handleClear}>
              <FormattedMessage id={'RangeFilter.clear'} />
            </button>
          </RangeFilterForm>
        </div>
      </div>
    );
  }
}

RangeFilterPlainComponent.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  step: number,
};

RangeFilterPlainComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  queryParamNames: arrayOf(string).isRequired,
  onSubmit: func.isRequired,
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  // form injectIntl
  intl: intlShape.isRequired,
};

const RangeFilterPlain = injectIntl(RangeFilterPlainComponent);

export default RangeFilterPlain;